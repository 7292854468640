#q-details-attachments{display:none; }

haeder img {max-width: 120px; }


@media screen {
  #printSection {
      display: none;
  }
}

.card {
    border: none;
} 

.questions,
.questions-cpp{
    
    .table-bordered {
        border: none;
        
        th {
            border: none;
            border-top: 1px solid #dee2e6;            
        }
        
        td {
            border: none;
            border-top: 1px solid #dee2e6;
            
            a {
                font-family: Times;
                font-size: 19px;
                line-height: 1.2;
                color: #343a40;             
     
                &:hover {
                    text-decoration: none;
                }
            }
            
            &.question-cat {
                text-transform: uppercase;
                max-width: 140px;
                font-size: 12px;
            }
            
        }
        
        @media (max-width: 767.98px) { 
            th.published,
            td.published{
                display: none;
            }
            
            td a {
                display: -webkit-box;
                -webkit-line-clamp: 2;              
            }
            
        }

    }

}

.questions {
    .table-bordered {
         td a{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: anywhere;
         }
    }
}


.modal {
    
    .question-published {
        font-family: Times;
        font-size: 19px;
        line-height: 1.2;
        margin: 1rem 0 2rem;;
    }

    a {
        color: #033e7c;
        &:hover {
            text-decoration: none;
            color: #343a40;
            border-bottom: 1px solid #033e7c;
        }
    }
    
    a[target="_blank"]:after {
        font-family: "Font Awesome 5 Free";
        content: '\f35d';
        font-weight: 600;
        padding-left: 5px;
    }
    
    ul {
        list-style-type: square;
        padding-inline-start: 20px;
        li::marker {
            color: #033e7c;
        }
    }

}
